<template>
    <f7-page name="home" class="homepage">

        <f7-toolbar bottom>
            <f7-link></f7-link>
            <f7-link>&copy; 2019 Initial Finance</f7-link>
            <f7-link></f7-link>
        </f7-toolbar>

        <f7-block>
            <f7-row>
                <f7-col width="100">
                    <img src="../assets/initial-finance-logo-white.png" class="homepageLogo" alt=""/>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-row>
                <f7-col width="100">
                    <f7-button large fill raised href="/loan/">How much can I borrow?</f7-button>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-row>
                <f7-col width="100">
                    <f7-button large outline fill raised href="/calculator/">Repayments Calculator</f7-button>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-row>
                <f7-col width="100">
                    <f7-button large outline fill raised href="/stamp-duty/">Stamp Duty Calculator</f7-button>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-row>
                <f7-col width="100">
                    <f7-button large outline fill raised @click="$refs.actionsOneGroup.open()">Contact Us</f7-button>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-actions ref="actionsOneGroup">
            <f7-actions-group>
                <f7-actions-label>Who would you like to speak with?</f7-actions-label>
                <f7-actions-button><a class="link external" href="tel:+61409226650">David</a></f7-actions-button>
                <f7-actions-button><a class="link external" href="tel:+61413033998">Peter</a></f7-actions-button>
            </f7-actions-group>
        </f7-actions>
    </f7-page>
</template>

<script>
    import navbar from '../components/navbar.vue';

    export default {
        components: {navbar},
        mounted() {
            this.$f7ready((f7) => {

                // let totalIncome = this.calculateTotalIncome();
                // let livingAllowance = this.calculateLivingAllowance();
                //
                // let totalIncomeAfterAllowance = totalIncome - livingAllowance;
                //
                // let totalCommitments = this.calculateCommitments();
                // let newLoanCommitments = this.calculateNewLoanCommitments();
                //
                // let netSurplus = totalIncomeAfterAllowance - totalCommitments - newLoanCommitments;
                //
                // let loanResult = 'refer';
                // if (netSurplus > 1) {
                //     loanResult = 'pass';
                // }
                //
                // let maximumLoanSize = this.calculateMaximumLoanSize(totalIncomeAfterAllowance - totalCommitments);
            });
        },
        methods: {

        }
    }
</script>
