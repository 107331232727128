<template>
    <f7-list inline-labels no-hairlines>

        <f7-list-input
                name="repayments_frequency"
                label="Frequency"
                type="select">
            <option value="weekly">Weekly</option>
            <option value="fortnightly">Fortnightly</option>
            <option value="monthly" selected>Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="annually">Annually</option>
        </f7-list-input>

        <f7-list-input
                name="repayments_personal_loans"
                label="Personal loans"
                placeholder="$0.00"
                v-money="money"
                class="repayments"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="repayments_car_loans"
                label="Car loans"
                placeholder="$0.00"
                v-money="money"
                class="repayments"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="repayments_rent"
                label="Rent"
                placeholder="$0.00"
                v-money="money"
                class="repayments"
                type="text">
        </f7-list-input>
    </f7-list>
</template>

<script>

    import {VMoney} from 'v-money';

    export default {
        data() {
            return {
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    suffix: ' ',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                }
            }
        },
        directives: {money: VMoney}
    }
</script>
