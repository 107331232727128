<template>
    <f7-list inline-labels no-hairlines>

        <f7-list-input
                name="expenses_frequency"
                label="Frequency"
                type="select">
            <option value="weekly">Weekly</option>
            <option value="fortnightly">Fortnightly</option>
            <option value="monthly" selected>Monthly</option>
            <option value="quarterly">Quarterly</option>
            <option value="annually">Annually</option>
        </f7-list-input>

        <f7-list-input
                name="general_living_expenses"
                label="General living"
                placeholder="$0.00"
                v-money="money"
                class="expenses"
                @keyup.native="expensesSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="medical_expenses"
                label="Medical"
                placeholder="$0.00"
                v-money="money"
                class="expenses"
                @keyup.native="expensesSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="school_fees"
                label="School fees"
                placeholder="$0.00"
                v-money="money"
                class="expenses"
                @keyup.native="expensesSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="child_card"
                label="Child care"
                placeholder="$0.00"
                v-money="money"
                class="expenses"
                @keyup.native="expensesSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="child_support"
                label="Child support"
                placeholder="$0.00"
                v-money="money"
                class="expenses"
                @keyup.native="expensesSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="other_expenses"
                label="Other"
                placeholder="$0.00"
                v-money="money"
                class="expenses"
                @keyup.native="expensesSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="total_expenses"
                label="Total"
                placeholder="$0.00"
                v-model="totalExpenses"
                readonly
                type="text">
        </f7-list-input>

        <f7-block>
            <f7-row>
                <f7-col>
                    <f7-button @click="prevStep()" outline>&larr; PREVIOUS</f7-button>
                </f7-col>
                <f7-col>
                    <f7-button type="submit" fill>FINISH</f7-button>
                </f7-col>
            </f7-row>

        </f7-block>

    </f7-list>
</template>

<script>

    import {VMoney} from 'v-money';

    export default {
        data() {
            return {
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    suffix: ' ',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                }
            }
        },
        directives: {money: VMoney},
        computed: {
            totalExpenses() {
                return this.expensesSum();
            }
        },
        methods: {
            expensesSum() {
                let expenses = 0;
                this.$$('#expenses').find('input[type=text]:not([readonly])').each(function (i, e) {
                    if (e.value) {
                        expenses = parseInt(e.value.replace(/\D/g, '')) + expenses;
                    }
                });

                this.$$('input[name=total_expenses]').val(expenses.toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }));
            },
            prevStep() {
                this.$f7.tab.show('#repayments-limits');
                this.$$('.page-content').scrollTop(0);
            },
            finishApplication() {

                // $$('form.form-ajax-submit').on('formajax:success', function (e) {
                //     var xhr = e.detail.xhr; // actual XHR object
                //
                //     var data = e.detail.data; // Ajax response from action file
                //     // do something with response data
                // });
            }
        },
    }
</script>
