<template>
    <f7-page name="calculator">

        <f7-navbar back-link>
            <f7-nav-title>Loan Repayment Calculator</f7-nav-title>
        </f7-navbar>

        <f7-list inline-labels no-hairlines>
            <f7-list-input
                    name="loan_amount"
                    label="Loan amount"
                    placeholder="$0.00"
                    :value="price"
                    @input="price = $event.target.value"
                    @keyup="checkPrice" @blur="onBlurPrice"
                    type="text">
            </f7-list-input>

            <f7-list-input
                    name="interest_rate"
                    label="Interest rate"
                    placeholder="$0.00"
                    :value="rate"
                    @input="rate = $event.target.value"
                    @keyup.enter="checkRate" @blur="checkRate"
                    type="text">
            </f7-list-input>

            <f7-list-input
                    name="loan_term"
                    label="Loan Term (years)"
                    type="number"
                    :value="term"
                    @input="term = $event.target.value"
                    max="30" min="1" step="1"
                    @keyup="checkTerm" @blur="checkTerm">
            </f7-list-input>

            <f7-list-input
                    name="frequency"
                    label="Repayment Frequency"
                    type="select"
                    :value="frequency"
                    @input="frequency = $event.target.value">
                <option v-for="f in frequencies" :value="f.value">{{ f.text }}</option>
            </f7-list-input>

        </f7-list>

        <f7-block-title>Repayments</f7-block-title>
        <f7-list>
            <f7-list-item :title="getFrequencyText + ' payment'" :after="toCurrency(payment)"></f7-list-item>
            <f7-list-item title="Total loan repayments" :after="toCurrency(total, 0)"></f7-list-item>
            <f7-list-item title="Total interest charged" :after="toCurrency(interest, 0)"></f7-list-item>
        </f7-list>


        <f7-block-footer>
            <p>*Please note rates and repayments are subject to change.</p>
        </f7-block-footer>

    </f7-page>
</template>

<script>

    export default {
        // define data props with default values
        data() {
            return {
                price: '$350,000',
                upfront: 0,
                rate: 4,
                term: 30,
                terms: [
                    {text: '15 years', value: 15},
                    {text: '20 years', value: 20},
                    {text: '30 years', value: 30}
                ],
                frequency: 12,
                frequencies: [
                    {text: 'Weekly', value: 52},
                    {text: 'Fortnightly', value: 26},
                    {text: 'Monthly', value: 12}
                ],
                visible: false,
            }
        },

        // methods
        methods: {
            // format value to currency
            toCurrency(value, cents = 2) {

                // if invalid, return two en dashes
                if (isNaN(value) || !isFinite(value)) return '-';

                // else make it pretty and return
                return '$' + value.toFixed(cents).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

            },
            // check loan term
            checkTerm() {
                var value = String(this.term).replace(/[^0-9]/g, '');
                if (value == '' || isNaN(value)) {
                    value = 30;
                }
                this.term = (value < 30) ? value : '30';
            },
            // validate the rate
            checkRate() {

                // remove non numeric chars (except decimal)
                var value = String(this.rate).replace(/[^0-9.]/g, '');

                // if value is empty or invalid, set to 0
                if (value == '' || isNaN(value)) value = '0';

                // if first char is decimal, add a leading zero
                if (value.charAt(0) == '.') value = '0' + value;

                // update the value (max is 100)
                this.rate = (value < 100) ? value : '100';

            },
            // validate the price
            checkPrice() {

                // remove leading zeros and non numeric chars (except decimal)
                var value = String(this.price).replace(/[^0-9.]/g, '').replace(/^0+/, '');

                // limit to no more than 2 decimal places
                if (value.includes('.')) {
                    if (value.split('.')[1].length > 2) value = value.slice(0, -1);
                }

                // if the value is invalid, just display dollar sign and return
                if (value == '' || isNaN(value)) {
                    this.price = '$';
                    return;
                }

                // else make it pretty
                this.price = '$' + String(value).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

            },
            // check price when the field loses focus
            onBlurPrice() {
                // if value is less than two chars, set it to '$0'
                if (this.price.length < 2) this.price = '$0';
            }
        },

        // calculations
        computed: {
            getFrequencyText: function () {
                switch (this.frequency) {
                    case "26":
                        return 'Fortnightly';
                    case "52":
                        return 'Weekly';
                    case "12":
                    default:
                        return 'Monthly';
                }
            },
            // price as int (remove currency formatting)
            priceInt: function () {
                var price = parseFloat(String(this.price).replace(/[^0-9.]/g, ''));
                return (isNaN(price)) ? 0 : price;
            },

            // down payment = price * (upfront / 100)
            down: function () {
                return this.priceInt * (parseInt(this.upfront) / 100);
            },

            // loan amount = price - down payment
            loan: function () {
                return this.priceInt - this.down;
            },

            // months = term * 12
            months: function () {
                return this.term * parseInt(this.frequency);
            },

            // monthly payment calc
            payment: function () {
                var P = this.loan;
                var r = parseFloat(this.rate / (parseInt(this.frequency) * 100));
                var N = parseInt(this.months);
                return (r == 0) ? P / N : P * (r * Math.pow((1 + r), N)) / (Math.pow((1 + r), N) - 1);
            },

            // total mortgage cost = months * monthly payment + down payment
            total: function () {
                return this.months * this.payment + this.down;
            },

            // total interest payable = total cost - down payment - loan amount
            interest: function () {
                return this.total - this.down - this.loan;
            },
        },

    }
</script>