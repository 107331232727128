<template>
    <f7-page name="form">

        <f7-navbar title="Borrowing Calculator" back-link=""></f7-navbar>


        <form id="loan-application" class="form-store-data form-ajax-submit" method="POST" action="./php/mail.php">
            <f7-tabs animated>
                <f7-tab id="general" active>
                    <f7-block-title>General</f7-block-title>
                    <General/>
                </f7-tab>
                <f7-tab id="borrower-1">
                    <f7-block-title>Borrower 1</f7-block-title>
                    <Borrower1/>
                </f7-tab>
                <f7-tab id="borrower-2">
                    <f7-block-title>Borrower 2</f7-block-title>
                    <Borrower2/>
                </f7-tab>
                <f7-tab id="repayments-limits">
                    <f7-block-title>Repayments</f7-block-title>
                    <Repayments/>
                    <f7-block-title>Limits</f7-block-title>
                    <Limits/>
                </f7-tab>
                <f7-tab id="expenses">
                    <f7-block-title>Expenses</f7-block-title>
                    <Expenses/>
                </f7-tab>
                <f7-tab id="summary">
                    <f7-block-title>Summary</f7-block-title>
                    <Summary/>
                </f7-tab>
            </f7-tabs>
        </form>
    </f7-page>

</template>

<script>
    import navbar from '../components/navbar.vue';
    import General from './steps/general.vue';
    import Borrower1 from './steps/borrower-1.vue';
    import Borrower2 from './steps/borrower-2.vue';
    import Repayments from './steps/repayments.vue';
    import Limits from './steps/limits.vue';
    import Expenses from './steps/expenses';
    import Summary from './steps/summary';

    export default {
        components: {navbar, General, Borrower1, Borrower2, Repayments, Limits, Expenses, Summary},
        methods: {
            calculateTotalIncome() {

            }
        }
    }
</script>
