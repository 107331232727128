<template>
    <f7-list inline-labels no-hairlines>

        <f7-list>
            <f7-list-item title="Net income" :after="toCurrency(showTotalIncome, 2)"></f7-list-item>
            <f7-list-item title="Commitments" :after="toCurrency(showCommitments, 2)"></f7-list-item>
            <f7-list-item title="NDI" :after="showNDI"></f7-list-item>
            <f7-list-item class="result" title="Result" :after="showResult"></f7-list-item>
        </f7-list>

        <f7-block>
            <f7-row>
                <f7-col>
                    <f7-button @click="startAgain()" outline>&larr; BACK TO THE START</f7-button>
                </f7-col>
                <f7-col>
                    <f7-button @click="refresh()" fill>HOME</f7-button>
                </f7-col>
            </f7-row>

        </f7-block>

    </f7-list>
</template>

<script>


    export default {
        data() {
            return {
                totalIncome: 0,
                livingAllowance: 0,
                totalIncomeAfterAllowance: 0,
                totalCommitments: 0,
                newLoanCommitments: 0,
                netSurplus: 0,
                ndi: 0,
                $this: this
            }
        },
        computed: {
            showTotalIncome() {
                return this.totalIncome * 12;
            },
            showCommitments() {
                return this.totalCommitments * 12;
            },
            showNDI() {
                this.ndi = (this.totalIncome / this.totalCommitments).toFixed(2);
                return this.ndi;
            },
            showResult() {
                if (this.ndi > 1) {
                    this.$$('li.result').find('span').addClass('pass');
                    return 'pass';
                }

                return 'refer';
            }
        },
        mounted() {
            let $this = this;
            this.$f7ready((f7) => {
                this.$f7.on('tabShow', function (e, t) {
                    if (e.id === 'summary') {
                        $this.refresh();
                    }
                })
            });
        },
        methods: {
            refresh() {
                this.totalIncome = this.calculateTotalIncome();
                this.livingAllowance = this.calculateLivingAllowance();
                this.totalIncomeAfterAllowance = this.totalIncome - this.livingAllowance;
                this.newLoanCommitments = this.calculateNewLoanCommitments();
                this.totalCommitments = this.calculateCommitments();
                this.netSurplus = this.calculateNetSurplus();
                this.$$('.page-content').scrollTop(0);
            },
            calculateTotalIncome() {
                let $this = this;

                let borrowerOneFrequency = this.$$('select[name=borrower_1_pay_frequency]').val();
                let borrowerTwoFrequency = this.$$('select[name=borrower_2_pay_frequency]').val();
                let borrowerOneTotalIncome = 0;
                let borrowerTwoTotalIncome = 0;
                let totalIncome = 0;

                this.$$('.income-1 input').each(function (index, element) {
                    borrowerOneTotalIncome += $this.stringToFloat($this.$$(element).val());
                });

                totalIncome += this.calculateMonthlyPay(borrowerOneTotalIncome, borrowerOneFrequency);

                this.$$('.income-2 input').each(function (index, element) {
                    borrowerTwoTotalIncome += $this.stringToFloat($this.$$(element).val());
                });

                totalIncome += this.calculateMonthlyPay(borrowerTwoTotalIncome, borrowerTwoFrequency);

                return totalIncome;

            },
            calculateMonthlyPay(income, frequency) {
                switch (frequency) {
                    case "weekly":
                        income = (income * 52) / 12;
                        break;
                    case "fortnightly":
                        income = (income * 26) / 12;
                        break;
                    case "quarterly":
                        income = (income * 4) / 12;
                        break;
                    case "annually":
                        income = income / 12;
                        break;
                    case "monthly":
                    default:
                        break;
                }
                return income;
            },
            calculateLivingAllowance() {
                return 0;
                // let maritalStatus = 'single';
                // let numberOfDependants = '2';
                // let livingAllowance = 0;
                // if (maritalStatus === 'single') {
                //     livingAllowance = 1091;
                // } else {
                //     livingAllowance = 1586.17;
                // }
                //
                // livingAllowance = livingAllowance + (numberOfDependants * 385.42);
                //
                // return parseFloat(livingAllowance).toFixed(2);

            },
            calculateCommitments() {

                let $this = this;

                //Calculate Repayments
                let repaymentsFrequency = this.$$('select[name=repayments_frequency]').val();
                let totalRepayments = 0;

                this.$$('.repayments input').each(function (index, element) {
                    totalRepayments += $this.stringToFloat($this.$$(element).val());
                });

                totalRepayments = this.calculateMonthlyPay(totalRepayments, repaymentsFrequency);

                //Credit Card Commitments
                let creditCardMonthly = this.stringToFloat(this.$$('input[name=repayments_credit_card]').val()) * 0.038;

                //Home Loan Commitments
                let homeLoanPI = this.stringToFloat(this.$$('input[name=repayments_home_invest_PI]').val());
                let homeLoanIO = this.stringToFloat(this.$$('input[name=repayments_home_invest_IO]').val());

                let totalLoanCommitments = this.calculateLoanCommitments(homeLoanPI) + this.calculateLoanCommitments(homeLoanIO);

                totalLoanCommitments += totalRepayments + totalLoanCommitments + creditCardMonthly;

                let expensesFrequency = this.$$('select[name=expenses_frequency]').val();
                let totalExpenses = 0;

                this.$$('.expenses input').each(function (index, element) {
                    totalExpenses += $this.stringToFloat($this.$$(element).val());
                });

                totalExpenses = this.calculateMonthlyPay(totalExpenses, expensesFrequency);

                return parseFloat(totalLoanCommitments + totalExpenses + this.newLoanCommitments);
            },
            calculateLoanCommitments(newLoanAmount) {
                let interestRate = 6.5 / 100;
                let loanTerm = 30;

                let pmt = Math.abs(this.PMT(interestRate / 12, loanTerm * 12, newLoanAmount).toFixed(2));
                return pmt;
            },
            calculateNewLoanCommitments() {
                let newLoanAmount = this.stringToFloat(this.$$('input[name=total_loan]').val());
                let interestRate = 6.5 / 100;
                let loanTerm = 30;

                let pmt = Math.abs(this.PMT(interestRate / 12, loanTerm * 12, newLoanAmount).toFixed(2));
                return pmt;
            },
            calculateMaximumLoanSize(pmt) {
                let pv = Math.abs(this.PV(6.5 / 100 / 12, 30 * 12, pmt, 0, 0));
                return pv;
            },
            calculateNetSurplus() {
                return this.totalIncome - this.livingAllowance - this.totalCommitments - this.newLoanCommitments;
            },
            PMT(ir, np, pv, fv, type) {
                /*
                 * ir   - interest rate per month
                 * np   - number of periods (months)
                 * pv   - present value
                 * fv   - future value
                 * type - when the payments are due:
                 *        0: end of the period, e.g. end of month (default)
                 *        1: beginning of period
                 */
                var pmt, pvif;

                fv || (fv = 0);
                type || (type = 0);

                if (ir === 0)
                    return -(pv + fv) / np;

                pvif = Math.pow(1 + ir, np);
                pmt = -ir * pv * (pvif + fv) / (pvif - 1);

                if (type === 1)
                    pmt /= (1 + ir);

                return pmt;
            },
            PV(rate, periods, payment, future, type) {
                // Initialize type
                var type = (typeof type === 'undefined') ? 0 : type;

                // Evaluate rate and periods (TODO: replace with secure expression evaluator)
                rate = eval(rate);
                periods = eval(periods);

                // Return present value
                if (rate === 0) {
                    return -payment * periods - future;
                } else {
                    return (((1 - Math.pow(1 + rate, periods)) / rate) * payment * (1 + rate * type) - future) / Math.pow(1 + rate, periods);
                }
            },
            stringToFloat(money) {
                let result = money.replace(/[^0-9.]/g, '');
                if (result) {
                    return parseFloat(result);
                } else {
                    return 0;
                }
            },
            toCurrency(value, cents = 2) {

                // if invalid, return two en dashes
                if (isNaN(value) || !isFinite(value)) return '&ndash;&ndash;';

                // else make it pretty and return
                return '$' + value.toFixed(cents).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

            },
            startAgain() {
                this.$f7.tab.show('#general');
                this.$$('.page-content').scrollTop(0);
            },
            goHome() {
                this.$f7.form.removeFormData('#loan-application');
                document.location = './';
            }
        },
    }
</script>
