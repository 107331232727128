
import HomePage from '../pages/home.vue';
import LoanPage from '../pages/loan.vue';
import CalculatorPage from '../pages/calculator.vue';
import StampDutyPage from '../pages/stamp-duty.vue';

import NotFoundPage from '../pages/404.vue';

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/loan/',
    component: LoanPage,
  },
  {
    path: '/calculator/',
    component: CalculatorPage,
  },
  {
    path: '/stamp-duty/',
    component: StampDutyPage,
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
