<template>
<f7-app :params="f7params" >
  <f7-statusbar></f7-statusbar>
  <f7-view main class="safe-areas" url="/"></f7-view>
</f7-app>
</template>
<script>

  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          name: 'Initial Finance', // App name
          theme: 'auto', // Automatic theme detection
          // App root data
          data: function () {
            return {
            };
          },
          // App routes
          routes: routes,
        },

      }
    },
    methods: {

    },
    mounted() {
      this.$f7ready((f7) => {
        // Call F7 APIs here
      });
    }
  }
</script>