<template>
    <f7-page name="calculator">

        <f7-navbar back-link>
            <f7-nav-title>Stamp Duty Calculator</f7-nav-title>
        </f7-navbar>

        <f7-block>
            <div id="calculator">
                <div id="stamp-duty-calculator" class="grey-theme hidden">
                </div>
            </div>
        </f7-block>

    </f7-page>
</template>

<script>
    import '../css/calculator.scss';
    export default {
        data() {
            return {}
        },
        mounted() {

            this.$f7ready((f7) => {
                let $this = this;
                let calculatorScript = document.createElement('script');
                calculatorScript.setAttribute('src', '//calculatorsonline.com.au/external/!main/stamp_duty.min.js');
                calculatorScript.onload = () => {
                    setTimeout(function () {
                        $this.$$('.stamp-calculate').addClass('black-theme');
                        let customCss = "<style>.grey-theme .state-module button, .grey-theme input.stamp-calculate {background: #000000 !important;}</style>";
                        $this.$$('head').append(customCss);

                    }, 500);

                };
                this.$$('#calculator').append(calculatorScript);
            });
        },
        methods: {
            calculatorLoaded: function () {
                alert(1);
                this.$$('.grey-theme input.stamp-calculate').addClass('black-theme');
            }
        }
    }
</script>