<template>
    <f7-list inline-labels no-hairlines>

        <f7-list-input
                name="repayments_credit_card"
                label="Credit card"
                placeholder="$0.00"
                v-money="money"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="repayments_home_invest_PI"
                label="Home/Invest P&I"
                placeholder="$0.00"
                v-money="money"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="repayments_home_invest_IO"
                label="Home/Invest IO"
                placeholder="$0.00"
                v-money="money"
                type="text">
        </f7-list-input>

        <f7-block>
            <f7-row>
                <f7-col>
                    <f7-button @click="prevStep()" outline>&larr; PREVIOUS</f7-button>
                </f7-col>
                <f7-col>
                    <f7-button @click="nextStep()" fill>NEXT &rarr;</f7-button>
                </f7-col>
            </f7-row>

        </f7-block>

    </f7-list>
</template>

<script>


    import {VMoney} from 'v-money';

    export default {
        data() {
            return {
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    suffix: ' ',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                }
            }
        },
        directives: {money: VMoney},
        methods: {
            prevStep() {
                let formData = this.$f7.form.getFormData('#loan-application');
                if (formData && formData.number_of_borrowers == 2) {
                    this.$f7.tab.show('#borrower-2');
                } else {
                    this.$f7.tab.show('#borrower-1');
                }

                this.$$('.page-content').scrollTop(0);
            },
            nextStep() {
                this.$f7.tab.show('#expenses');
                this.$$('.page-content').scrollTop(0);
            }
        },
    }
</script>
