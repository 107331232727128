<template>
    <f7-list inline-labels no-hairlines>

        <f7-list-input
                name="number_of_borrowers"
                label="Number of borrowers"
                type="select">
            <option value="1">1</option>
            <option value="2">2</option>
        </f7-list-input>

        <f7-list-input
                name="marital_status"
                label="Marital status"
                type="select">
            <option value="single">Single</option>
            <option value="couple with applicant">Couple with applicant</option>
            <option value="couple with non-applicant">Couple with non-applicant</option>
        </f7-list-input>


        <f7-list-input
                name="dependants"
                label="Dependants"
                type="select">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
        </f7-list-input>

        <f7-list-input
                name="purpose"
                label="Purpose"
                type="select">
            <option value="buying my home">Buying my home</option>
            <option value="refinancing my home">Refinancing my home</option>
            <option value="building a house">Building a house</option>
            <option value="refinancing my investment property">Refinancing my investment property</option>
            <option value="buying an investment property">Buying an investment property</option>
        </f7-list-input>

        <f7-list-input
                name="loan_amount_PI"
                label="Loan amount (P/I)"
                placeholder="$0.00"
                v-money="money"
                @keyup.native="loanSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="loan_amount_IO"
                label="Loan amount (IO)"
                placeholder="$0.00"
                v-money="money"
                @keyup.native="loanSum"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="total_loan"
                label="Total loan"
                type="text"
                v-model="totalLoan"
                input-style="font-weight: 600;"
                readonly
        >
        </f7-list-input>

        <f7-list-input
                name="loan_term"
                label="Loan Term"
                type="select">
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="30" selected>30</option>
        </f7-list-input>

        <f7-list-input
                name="estimated_valuation"
                label="Est. Valuation"
                placeholder="$0.00"
                v-money="money"
                @keyup.native="calculateLVR"
                type="text">
        </f7-list-input>

        <f7-list-input
                name="lvr"
                label="LVR"
                placeholder="0%"
                v-model="totalLVR"
                type="text">
        </f7-list-input>

        <f7-block>
            <f7-row>
                <f7-col>
                    <f7-button @click="nextStep()" fill>NEXT &rarr;</f7-button>
                </f7-col>
            </f7-row>

        </f7-block>

    </f7-list>
</template>

<script>

    import {VMoney} from 'v-money';

    export default {
        data() {
            return {
                money: {
                    decimal: '.',
                    thousands: ',',
                    prefix: '$',
                    suffix: ' ',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                }
            }
        },
        directives: {money: VMoney},
        computed: {
            totalLoan() {
                return this.loanSum();
            },
            totalLVR() {
                return this.calculateLVR();
            }
        },
        methods: {
            nextStep() {
                this.$f7.tab.show('#borrower-1');
                this.$$('.page-content').scrollTop(0);
            },
            calculateLVR() {

                let valuation = this.$$('input[name=estimated_valuation]').val();
                let loanTotal = this.$$('input[name=total_loan]').val();
                let lvr = this.$$('input[name=lvr]');

                if (valuation && loanTotal) {
                    loanTotal = parseInt(loanTotal.replace(/\D/g, ''));
                    valuation = parseInt(valuation.replace(/\D/g, ''));

                    if (loanTotal > 0 && valuation > 0) {
                        lvr.val((((((loanTotal))) / (valuation)) * 100).toFixed(0) + '%');
                    } else {
                        lvr.val('');
                    }

                }

            },
            loanSum() {

                let loanPI = this.$$('input[name=loan_amount_PI]').val();
                let loanIO = this.$$('input[name=loan_amount_IO]').val();

                let loanTotal = this.$$('input[name=total_loan]');

                if (!loanPI) {
                    loanPI = 0;
                } else {
                    loanPI = loanPI.replace(/\D/g, '');
                }

                if (!loanIO) {
                    loanIO = 0;
                } else {
                    loanIO = loanIO.replace(/\D/g, '');
                }

                loanTotal.val(((parseInt(loanPI) + parseInt(loanIO))).toLocaleString('en-AU', {
                    style: 'currency',
                    currency: 'AUD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }));

                this.calculateLVR();
            }
        },
    }
</script>
